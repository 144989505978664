import { useState } from 'react';
import { useIntl } from 'react-intl';
import { withAuthRequired } from '@godaddy/gasket-auth';
import { withLocaleRequired } from '@gasket/react-intl';
import Text from '@ux/text';
import Button from '@ux/button';
import { asOptions } from '@ux/space';
import spaceClasses from '@ux/space/classes';
import { Recommendations } from '@px/recommendations';

import { SECUI_MY_SITES_V2 } from '@/common/flags';
import { withFlagProtected } from '@/components/flags';
import { isSucuriUI } from '@/client/utils/sucuriUICheck';

import Head from '../components/head';
import { getPlans } from '../packages/wss-context/src';
import MySitesController from '../packages/my-sites/controller';
import SecurityPlansController from '../packages/security-plans/controller';

const MySites = (props) => {
  const intl = useIntl();
  const { sites, plans, serverConfig } = props;

  const [recommendationsOpen, openRecommendations] = useState(false);

  return (
    <>
      <Head title={intl.formatMessage({ id: 'common__website_security' })} />
      <div
        className={spaceClasses({
          as: asOptions.MODULE,
          block: true,
          gap: true,
          className:
            'container my-sites-container d-flex flex-column flex-md-row justify-content-md-between align-items-md-center px-2',
        })}
      >
        <Text.h1
          as="title"
          size={5}
          text={intl.formatMessage({ id: 'wss_title' })}
          className="mb-0"
        />
        <div className="d-flex justify-content-start justify-content-md-between ml-md-auto">
          <Button
            className="ml-md-3"
            design="secondary"
            href={
              isSucuriUI()
                ? 'https://support.sucuri.net/support/'
                : '/_redirect/support/new'
            }
            text={intl.formatMessage({
              id: 'wss_setup_success_base__request_support',
            })}
          />
          {isSucuriUI() ? (
            <Button
              className="ml-3"
              design="primary"
              target="_blank"
              href={`${serverConfig?.sucuri_account_portal}/settings/billing`}
              text={intl.formatMessage({ id: 'upgrade_plan__button' })}
            />
          ) : (
            <Button
              className="ml-3"
              design="primary"
              onClick={() => openRecommendations(true)}
              text={intl.formatMessage({ id: 'noplans__button' })}
            />
          )}
        </div>
      </div>
      {!isSucuriUI() && (
        <>
          <SecurityPlansController plans={plans} />

          {recommendationsOpen && (
            <Recommendations
              id="wss-recommendation"
              component="nextSteps"
              placementKey="webSecurityPlans"
              appKey="web-security"
              openInModal={true}
              onPurchaseComplete={() => {
                openRecommendations(false);
                if (typeof window !== 'undefined') {
                  window.location.reload();
                }
              }}
            />
          )}
        </>
      )}
      <MySitesController sites={sites} />
    </>
  );
};

export async function getServerSideProps({ req, res }) {
  const user = req?.gdAuth?.token;
  const plid = req?.gdAuth?.jwt?.idp?.plid;

  try {
    const plans = await getPlans(user, plid);

    return {
      props: {
        sites: [],
        plans: plans?.data?.subscriptions,
      },
    };
  } catch (error) {
    const message = error?.response?.data?.message || error.message;
    req
      .log(__filename)
      .error(`Failed to server render plans for My Sites V2 - ${message}`, {
        req,
        res,
      });

    return {
      props: {
        sites: [],
        plans: [],
      },
    };
  }
}

MySites.propTypes = {};

export default withAuthRequired({ realm: 'idp', initialProps: false })(
  withFlagProtected(
    withLocaleRequired('/locales', { initialProps: false })(MySites),
    SECUI_MY_SITES_V2,
  ),
);
