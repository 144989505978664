import PropTypes from 'prop-types';
import Text from '@ux/text';
import { FormattedMessage, useIntl } from 'react-intl';
import spaceClasses from '@ux/space/classes';
import { asOptions } from '@ux/space';
import GdTheGo from '@ux/icon/gd-the-go';
import X from '@ux/icon/x';
import Disrupt from '@ux/disrupt';
import Button from '@ux/button';
import { Intents } from '@ux/intents';
import { data as uxdata } from '@ux/data';

import { getInternalClientSideServiceUrl } from '../../environment-utils';

/**
 * Component to display an error message and a retry button.
 *
 * @param {Function} props.recheckHandler - The function to call when the retry button is clicked.
 *
 * @returns {JSX.Element} The SecurityPlansError component.
 */
export const SecurityPlansError = ({ recheckHandler }) => {
  const intl = useIntl();
  return (
    <div>
      <div className="justify-content-center text-center error">
        <div id="custom-error" className="p-2">
          <Text.h2
            size={1}
            as="title"
            text={intl.formatMessage({
              id: 'plansettingsmodal__failed_to_load_context',
            })}
          />
          {recheckHandler && (
            <div className="d-flex justify-content-center mt-4">
              <Button
                design="secondary"
                onClick={recheckHandler}
                text={intl.formatMessage({ id: 'mysites__retry_sites' })}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SecurityPlansError.propTypes = {
  recheckHandler: PropTypes.func,
};

/**
 * Component to display a message when no plans are found.
 *
 * @returns {JSX.Element} The NoPlansFound component.
 */
export const NoPlansFound = () => {
  const intl = useIntl();
  return (
    <div>
      <div className="justify-content-center text-center error">
        <div id="custom-error" className="p-2">
          <Text.h2
            size={1}
            as="title"
            text={intl.formatMessage({
              id: 'plansettingsmodal__no_plans',
            })}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Opens the setup page for a security plan.
 * @param {string} externalId - The external ID of the plan.
 * @returns {void}
 */
const doSetup = (externalId) => {
  if (!externalId) return;
  const baseURl = getInternalClientSideServiceUrl({ subdomain: 'setup' });

  window.open(`${baseURl}/security/${externalId}#security-start`, '_blank');
};

/**
 * Renders a card component for a security plan.
 *
 * This component displays information about a security plan and provides a button to set up the plan.
 * When the setup button is clicked, it triggers a loading state until the setup process is complete.
 *
 * @param {Object} props.plan - The security plan object.
 * @param {number} props.plan.id - The unique identifier of the plan.
 * @param {string} props.plan.name - The name of the plan.
 * @param {number} props.plan.availableSlots - The number of available slots in the plan.
 * @returns {JSX.Element} Security Plan Card
 */
export const SecurityPlanCard = ({ plan }) => {
  const intl = useIntl();

  return (
    <div
      key={plan.id}
      className={spaceClasses({
        as: asOptions.BLOCK,
        className: 'card ux-card plans-card mb-3',
        block: true,
      })}
    >
      <div className="card-block d-flex flex-column flex-md-row justify-content-between align-items-center">
        <div className="d-flex flex-column d-flex flex-column text-center text-md-left mb-3 mb-md-auto">
          <div className="h6 mb-0 ml-sm-2 mt-md-0 mr-3">
            <span className="font-weight-bold">{plan.name}</span>
          </div>
          <div className="mb-0 ml-sm-2 mt-md-0 mr-3 pt-2">
            <FormattedMessage
              id="plancard__x_domains_available"
              values={{ count: plan.availableSlots }}
            />
          </div>
        </div>
        <Button
          design="primary"
          onClick={() => doSetup(plan.externalId)}
          text={intl.formatMessage({
            id: 'setup_btn_text',
          })}
        />
      </div>
    </div>
  );
};

SecurityPlanCard.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    availableSlots: PropTypes.number,
  }),
};

/**
 * Renders a takeover modal for managing security plans.
 *
 * This component displays a takeover with a list of security plans. It allows users to view their plans
 * and select one for setup. The modal can be closed, which will hide the view without selecting a plan.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.planData - Object containing data about the plan, including loading state.
 * @param {Object[]} props.planList - Array of plan objects to be displayed.
 * @param {Function} props.setPlanView - Function to set the visibility of the plan view modal.
 * @returns {JSX.Element} Security Plans Takeover
 */
export const SecurityPlansTakeover = ({ planData, planList, setPlanView }) => {
  const intl = useIntl();
  const intents = new Intents();

  return (
    <Disrupt
      focusLock={true}
      strategy={'fixed'}
      className={'my-plans-container'}
      stretch={true}
      onClose={() => setPlanView(false)}
    >
      <div className="d-flex justify-content-center w-100 h-100">
        <button
          style={{
            background: intents.ux.box.backgroundColor,
            position: 'fixed',
            top: 35,
            right: 35,
            cursor: 'pointer',
            zIndex: 9999,
            border: 'none',
          }}
          onClick={() => setPlanView(false)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setPlanView(false);
            }
          }}
        >
          <X />
        </button>
        <div className="w-100 d-flex justify-content-center">
          <div className="col-md-8 col-sm-10 col-xs-12 text-center">
            <div
              className={spaceClasses({
                as: asOptions.MODULE,
                block: true,
              })}
            >
              <div className="plans-logo-container">
                {uxdata.privateLabelType === 1 && (
                  <GdTheGo height="50px" width="55px" />
                )}
                {uxdata.privateLabelType !== 1 && uxdata.logos?.desktop && (
                  <img src={uxdata.logos.desktop} />
                )}
              </div>
            </div>
            {planList.length > 0 && (
              <div
                className={spaceClasses({
                  as: asOptions.MODULE,
                  block: true,
                })}
              >
                <Text.h2
                  text={intl.formatMessage({
                    id: 'plancard_select_a_plan_set_up',
                  })}
                  as="heading"
                  size={1}
                />
              </div>
            )}

            {!planData?.isLoading && !planList?.length && <NoPlansFound />}
            {!planData?.isLoading &&
              planList.length > 0 &&
              planList.map((plan) => (
                <SecurityPlanCard plan={plan} key={plan.id} />
              ))}
          </div>
        </div>
      </div>
    </Disrupt>
  );
};

SecurityPlansTakeover.propTypes = {
  planData: PropTypes.shape({
    isLoading: PropTypes.bool,
  }),
  planList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  setPlanView: PropTypes.func,
};
