import { useEffect, useState } from 'react';
import Modal from '@ux/modal';
import { useIntl } from 'react-intl';
import { asOptions } from '@ux/space';
import Text from '@ux/text';
import Button from '@ux/button';
import spaceClasses from '@ux/space/classes';
import TrashIcon from '@ux/icon/trash';
import Alert from '@ux/alert';

const Confirmation = ({ confirm, cancel, pending }) => {
  const intl = useIntl();

  return (
    <div>
      <div className="mb-3">
        <Text.h6
          className="font-weight-bold mb-1 text-danger"
          text={intl.formatMessage({
            id: 'plansettingsmodal__confirm_heading',
          })}
        />
        <Text.p
          className="mb-0 text-danger"
          text={intl.formatMessage({ id: 'plansettingsmodal__confirm_body' })}
        />
      </div>
      {!pending && (
        <>
          <Button
            design="critical"
            onClick={() => confirm()}
            data-testid={'sec-plan-settings-card-confirm-remove-button'}
            text={intl.formatMessage({
              id: 'plansettingsmodal__button',
            })}
          />
          <Button
            design="primary"
            className="ml-5"
            onClick={() => cancel(null)}
            text={intl.formatMessage({ id: 'common__cancel' })}
          />
        </>
      )}
      {pending && (
        <Button
          disabled
          design="critical"
          text={intl.formatMessage({
            id: 'plansettingsmodal__button_inprogress',
          })}
        />
      )}
    </div>
  );
};

const RemoveSiteModal = ({
  show,
  setVisibility,
  selectedSite,
  confirmRemoval,
  inProgressRemoval,
}) => {
  const intl = useIntl();

  const [removeConfirmation, setRemoveConfirmation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!show) {
      setRemoveConfirmation(null);
      setError(null);
    }
  }, [show]);

  useEffect(() => {
    if (inProgressRemoval) {
      setError(null);
    }
  }, [inProgressRemoval]);

  /**
   * Internal helper method to remove a subscription
   * @param {number} id Subscription ID
   * @returns {Promise<void>} Promise
   */
  const remove = async (id) => {
    const result = await confirmRemoval(id);

    if (result && !result.success) {
      setError(result.error);
    }
  };

  return (
    <>
      {show && (
        <Modal
          id="remove-site-modal"
          title={intl.formatMessage({ id: 'mysites__remove_site' })}
          onClose={() => !inProgressRemoval && setVisibility(false)}
        >
          {error && (
            <Alert id="critical-message" emphasis="critical" className="mb-4">
              <Text.span text={error} />
            </Alert>
          )}
          <Text.h3
            data-testid="remove-modal-title"
            as="title"
            text={selectedSite?.domain}
          />
          <div
            className={spaceClasses({
              as: asOptions.LOOKUP,
            })}
          >
            {selectedSite?.subscriptions?.length > 1 &&
              selectedSite.subscriptions.map((sub) => (
                <div
                  key={sub.id}
                  data-testid="subscription-list-item"
                  className="col-12 card ux-card mt-3 mb-0"
                >
                  {removeConfirmation === sub.id ? (
                    <div className="p-4">
                      <Confirmation
                        pending={inProgressRemoval}
                        confirm={async () => await remove(sub.id)}
                        cancel={() => setRemoveConfirmation(null)}
                      />
                    </div>
                  ) : (
                    <div className="card-block p-4 d-flex justify-content-between align-items-center">
                      <Text.span as="paragraph" text={sub.name} />
                      <Button
                        design="inline"
                        className="pl-0 pr-0 ml-4"
                        onClick={() => {
                          setRemoveConfirmation(sub.id);
                        }}
                        disabled={inProgressRemoval}
                        data-testid={'sec-plan-settings-card-trash-button'}
                        aria-label={intl.formatMessage({
                          id: 'plansettingsmodal__button',
                        })}
                        icon={<TrashIcon width={24} height={24} />}
                      />
                    </div>
                  )}
                </div>
              ))}
          </div>
          {selectedSite?.subscriptions?.length <= 1 && (
            <>
              <Text.p text={selectedSite.subscriptions[0].name} />
              <Confirmation
                pending={inProgressRemoval}
                confirm={async () =>
                  await remove(selectedSite.subscriptions[0].id)
                }
                cancel={() => {
                  setRemoveConfirmation(null);
                  setVisibility(false);
                }}
              />
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default RemoveSiteModal;
