import { useState } from 'react';
import PropTypes from 'prop-types';
import spaceClasses from '@ux/space/classes';
import { asOptions } from '@ux/space';
import Text from '@ux/text';
import Button from '@ux/button';
import Spinner from '@ux/spinner';
import { useIntl } from 'react-intl';
import Search from '@ux/search';
import SearchIcon from '@ux/icon/search';

import { isSucuriUI } from '@/client/utils/sucuriUICheck';

import { CapabilityNames, getSiteClassNames } from '../lib/constants';
import FavouriteButton, {
  CapabilityColumn,
  SiteActions,
  SiteNotFound,
  SitesTableHeaderColumn,
} from '../lib';
import RemoveSiteModal from '../lib/remove-site-modal';

/**
 * My Sites Table Component
 *
 * @param {Object} props Deconstructed props
 * @param {Object} props.siteData Site data object
 * @param {Array} props.sitesList Array of user sites
 * @param {Function} props.onSortSites Sort sites function
 * @param {String} props.siteSort Site sort order
 * @param {Function} props.setSiteSearch Set site search function
 * @param {String} props.siteSuggestion Site search suggestion
 * @param {String} props.siteSearch Site search string
 * @param {Function} props.removeSite Remove sites function
 * @param {Function} props.toggleFavourite Toggle a site being marked as a favourite/non-favourite
 *
 * @returns {JSX.Element} React.component
 */
const MySitesTable = ({
  siteData,
  sitesList,
  onSortSites,
  siteSort,
  setSiteSearch,
  siteSuggestion,
  siteSearch,
  removeSite,
  toggleFavourite,
}) => {
  const intl = useIntl();

  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState({});
  const [isRemoving, setIsRemoving] = useState(false);

  const removeSiteSubscription = async (subscriptionId) => {
    try {
      setIsRemoving(true);
      await removeSite(subscriptionId);
      setShowRemoveModal(false);
      setIsRemoving(false);

      return {
        success: true,
      };
    } catch (error) {
      setIsRemoving(false);

      return {
        success: false,
        error: intl.formatMessage({ id: 'mysites__remove_site_error' }),
      };
    }
  };

  const tableHeaders = [
    {
      sortKey: 'domain',
      titleIntlId: 'mysites__site_url',
      className:
        'site-domain-th d-flex justify-content-between justify-content-lg-start align-items-center',
    },
    { sortKey: 'monitoring', titleIntlId: 'common__monitoring' },
    { sortKey: 'firewall', titleIntlId: 'mysites__firewall_cdn' },
    { sortKey: 'ssl', titleIntlId: 'common__ssl' },
    { sortKey: 'backups', titleIntlId: 'common__backups' },
  ];

  return (
    <>
      <RemoveSiteModal
        show={showRemoveModal}
        setVisibility={setShowRemoveModal}
        selectedSite={selectedSite}
        confirmRemoval={async (id) => await removeSiteSubscription(id)}
        inProgressRemoval={isRemoving}
      />
      <div
        className={spaceClasses({
          as: asOptions.MODULE,
          block: true,
          className: 'my-sites-search-form',
        })}
      >
        <form role="search">
          <Search
            button={<Button icon={<SearchIcon />} design="primary" />}
            id="my-sites-search"
            placeholder={intl.formatMessage({ id: 'common__search' })}
            value={siteSearch}
            onChange={setSiteSearch}
            suggestion={siteSuggestion}
            onKeyDown={(ev) =>
              ev.key === 'Tab' &&
              siteSuggestion &&
              setSiteSearch(siteSuggestion)
            }
          />
        </form>
      </div>
      <div
        className={spaceClasses({
          as: asOptions.BLOCK,
          className: 'my-sites-wrapper d-flex flex-column',
          block: true,
        })}
      >
        <div
          className={spaceClasses({
            as: asOptions.LOOKUP,
            className: 'lead py-3',
            inline: true,
          })}
        >
          {tableHeaders.map(({ sortKey, titleIntlId, className }) => (
            <SitesTableHeaderColumn
              className={className}
              key={sortKey}
              onClick={() => onSortSites(sortKey)}
              siteSort={siteSort[sortKey]}
              titleId={titleIntlId}
            />
          ))}
        </div>
        <div
          className={spaceClasses({
            as: asOptions.BLOCK,
            className: 'sites-list',
          })}
        >
          {siteData?.isLoading && (
            <div className="d-flex justify-content-center py-4">
              <Spinner size="lg" />
            </div>
          )}
          {!siteData?.isLoading && !sitesList.length && (
            <SiteNotFound
              clearFilters={() => setSiteSearch('')}
              siteSearch={siteSearch}
            />
          )}
          {!siteData?.isLoading &&
            sitesList.map((site) => {
              const classNames = getSiteClassNames(site);
              return (
                <div
                  key={site.domain}
                  className={spaceClasses({
                    as: asOptions.BLOCK,
                    inline: true,
                    className: `site-row py-4 py-lg-0 ${classNames}`,
                  })}
                >
                  <div className="d-flex justify-content-between justify-content-lg-start align-items-center">
                    <Text.h6
                      as="title"
                      className="mb-0 d-inline d-lg-none site-domain"
                      data-testid="site-domain"
                    >
                      {site.domain}
                    </Text.h6>
                    <Text.p className="mb-0 d-none d-lg-block site-domain">
                      {site.domain}
                    </Text.p>
                    <div className="d-flex justify-content-end d-lg-none">
                      <FavouriteButton
                        site={site}
                        toggleFavourite={toggleFavourite}
                      />
                      {!isSucuriUI() && (
                        <SiteActions
                          handleRemoveSite={() => {
                            setSelectedSite(site);
                            setShowRemoveModal(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {[
                    CapabilityNames.MONITORING,
                    CapabilityNames.FIREWALL,
                    CapabilityNames.SSL,
                    CapabilityNames.BACKUPS,
                  ].map((name) => (
                    <CapabilityColumn
                      key={name}
                      capability={site[name.toLowerCase()]}
                      domain={site.domain}
                      entitled={site.entitlements?.[name.toLowerCase()]}
                      subscriptions={site.subscriptions}
                      name={name}
                    />
                  ))}
                  {(!!site?.subscriptions?.length || isSucuriUI()) && (
                    <div className="d-none d-lg-flex justify-content-end py-1 py-lg-4">
                      <FavouriteButton
                        site={site}
                        toggleFavourite={toggleFavourite}
                      />
                      {!isSucuriUI() && (
                        <SiteActions
                          handleRemoveSite={() => {
                            setSelectedSite(site);
                            setShowRemoveModal(true);
                          }}
                        />
                      )}
                    </div>
                  )}
                  {!site?.subscriptions?.length && !isSucuriUI() && (
                    <div className="d-none d-lg-flex justify-content-end py-1 py-lg-4">
                      <Button
                        design="secondary"
                        text={intl.formatMessage({
                          id: 'mysites__secure_site',
                        })}
                      />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

MySitesTable.propTypes = {
  siteData: PropTypes.object,
  sitesList: PropTypes.array,
  onSortSites: PropTypes.func,
  siteSort: PropTypes.object,
  setSiteSearch: PropTypes.func,
  siteSuggestion: PropTypes.string,
  siteSearch: PropTypes.string,
  removeSite: PropTypes.func,
  toggleFavourite: PropTypes.func,
};

export default MySitesTable;
