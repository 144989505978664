import PropTypes from 'prop-types';
import Button from '@ux/button';
import { useIntl } from 'react-intl';
import { Spinner } from '@ux/uxcore2';
import AddIcon from '@ux/icon/add';
import spaceClasses from '@ux/space/classes';
import { asOptions } from '@ux/space';
import { useState } from 'react';
import Text from '@ux/text';

import { SecurityPlansTakeover } from '../lib';

/**
 * Security Plans Controller for rendering purchased plans
 * @param {Array} props.planList Array of active plans
 * @param {Object} props.planData Plan data object
 *
 * @returns {JSX.Element} Security Plans
 */
const SecurityPlans = ({ planData, planList }) => {
  const [showPlans, setPlanView] = useState(false);
  const intl = useIntl();
  document.body.style.overflow = showPlans ? 'hidden' : 'auto';
  if (!planList || !planList.length) {
    return;
  }

  if (planData.isLoading) {
    return (
      <div className="container d-flex justify-content-center py-4">
        <Spinner label="Loading Plans" size="lg" />
      </div>
    );
  }
  return (
    <>
      <div className="container my-sites-container d-flex flex-column">
        <div
          className={spaceClasses({
            as: asOptions.BLOCK,
            className: 'card ux-card plans-card my-3',
            block: true,
          })}
        >
          <div className="card-block d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
            <div className="d-flex flex-column d-flex flex-column text-sm-center text-md-left mb-4 mb-md-0">
              <Text.h1
                className="font-weight-bold mb-0"
                text={intl.formatMessage(
                  { id: 'plancard__x_plans_available_set_up' },
                  { count: planList.length },
                )}
                as="title"
                size={1}
              />
            </div>
            <Button
              icon={<AddIcon />}
              design="primary"
              onClick={() => setPlanView(true)}
              text={intl.formatMessage({ id: 'setup_btn_text' })}
            />
          </div>
        </div>
      </div>
      {showPlans && (
        <SecurityPlansTakeover
          planData={planData}
          planList={planList}
          setPlanView={setPlanView}
        />
      )}
    </>
  );
};

SecurityPlans.propTypes = {
  planData: PropTypes.object,
  planList: PropTypes.array,
};

export default SecurityPlans;
